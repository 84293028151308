import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Auth } from "./storage";
import { useRecoilValue } from "recoil";
import userAtom from "../atoms/user.atom";

const PrivateRoute = () => {
  const token = Auth.getToken();
  const y = useP2(token)

  return token || y ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;

const useP2 = (token) => {
  const userLogin = useRecoilValue(userAtom);
  return userLogin.access
};
