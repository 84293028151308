import { toast } from "react-hot-toast";
import { USER_API_URL } from "./constants";
import { Auth } from "./storage";

export const handleToastError = (errorMessage) => {
  if (typeof errorMessage === "object") {
    for (let key in errorMessage) {
      toast.error(`${key}: ${errorMessage[key][0]}`);
    }
  } else {
    toast.error(errorMessage);
  }
};
const refreshAccessToken = async () => {
  try {
    const response = await fetch(`${USER_API_URL}/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: Auth.getRefresh() }),
    });
    const data = await response.json();
    const newAccessToken = data.access;
    localStorage.setItem(
      "recoil-persist",
      JSON.stringify({ "user-login": { access: newAccessToken } })
    );
    window.location.reload();
  } catch (error) {
    console.error("Error refreshing access token:", error);
  }
};

export const handleError = (errorMessage) => {
  if (
    errorMessage === "Invalid Signature" ||
    errorMessage === "Unauthenticated" ||
    errorMessage === "Invalid Token."
  ) {
    localStorage.removeItem("recoil-persist");
    window.location.reload();
  } else if (errorMessage === "Expired Token") {
    refreshAccessToken();
  } else if (!navigator.onLine) {
    toast.error("You sre offline")
  }
  else {
    toast.error(errorMessage);
  }
};

export const timeToAMPM = (timeString) => {
  if (!timeString){
    return false
  }
  const [hours, minutes] = timeString.split(':');
  const parsedHours = parseInt(hours, 10);
  
  if (parsedHours === 0) {
    return `12:${minutes} AM`;
  } else if (parsedHours < 12) {
    return `${parsedHours}:${minutes} AM`;
  } else if (parsedHours === 12) {
    return `12:${minutes} PM`;
  } else {
    const pmHours = parsedHours - 12;
    return `${pmHours}:${minutes} PM`;
  }
}