import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
export default atom({
  key: "user-login",
  default: {
    user: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      user_id: "",
      is_member: false,
      is_speaker: false,
      profile_picture: "",
      is_admin: false,
    },
    access: "",
    refresh: "",
  },
  effects_UNSTABLE: [persistAtom],
});
